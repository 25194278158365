<template>
  <v-card class="mx-auto px-6 py-8" width="344">
    <v-card-title primary-title class="justify-center">
      <v-img :src="logo_url" max-width="250px" alt="wwcalc"/>
    </v-card-title>
    <br>
    <div v-if="!resetedPassword">
      <p style="padding: 10px; margin-bottom: 30px;">{{ selectedLanguage['passwordReset_component_text1'] }} {{ $route.params.email }} {{ selectedLanguage['passwordReset_component_text2'] }} 🌐{{ selectedLanguage['passwordReset_component_text3'] }}</p>
      <v-form v-model="form" @submit.prevent="onSubmit">
        <v-text-field v-model="password" :readonly="loading" :rules="rules.password" type="password" :label="selectedLanguage['passwordReset_component_text4']" :placeholder="selectedLanguage['passwordReset_component_text5']"/>
        <v-text-field v-model="confirmPassword" :readonly="loading" :rules="[required]" type="password" :label="selectedLanguage['passwordReset_component_text6']" :placeholder="selectedLanguage['passwordReset_component_text7']"/>
        <br>
        <v-btn :disabled="!form" :loading="loading" block color="primary" size="large" type="submit" variant="elevated">
          {{ selectedLanguage['passwordReset_component_text8'] }}
        </v-btn>
        <div class="overlay" v-if="formMessage">
          <v-alert border="top" dense color="red lighten-2" dark>
            {{formMessage}}
          </v-alert>
        </div>
      </v-form>
    </div>
    <div v-else>
      <v-progress-circular indeterminate :color="color"></v-progress-circular>
      {{ selectedLanguage['passwordReset_component_text9'] }}
    </div>
    <div v-if="resetedPassword==true">
      
    </div>
    <div v-else-if="resetedPassword==false">
      
    </div>
  </v-card>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex'
  import { portuguese } from '../languages/pt-br';
  import { english } from '../languages/en';

  export default {
    name: 'PasswordReset',
    computed:{
      ...mapGetters(['profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    data: () => ({
      logo_url: '/img/logo_color.png',
      logged: false,
      form: false,
      email: null,
      password: null,
      confirmPassword: null,
      loading: false,
      resetedPassword: false,
      formMessage: undefined,
      rules: {
        required: value => !!value || 'Field is required',
        password: [
          value => !!value || 'Please enter a password',
          (value) => !!value || 'Please type password.',
          // (value) => (value && /\d/.test(value)) || 'At least one digit',
          // (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
          (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
          (value) => (value && value.length > 9 ) || 'minimum 10 characters',
        ]
      },
    }),
    methods: {
      ...mapActions(['login','passwordReset']),
      required (v) {
        return !!v || 'Field is required'
      },
      async onSubmit () {
        this.formMessage = undefined
        if(this.password!=this.confirmPassword){
          this.formMessage = 'Confirm password not equal Password'
          return
        }
        this.loading = true
        const result = await this.passwordReset({"email": this.$route.params.email, "reset_token": this.$route.params.reset_token, "password": this.password})
        this.loading = false
        this.resetedPassword = result
        if(await this.login({"email": this.email,"password": this.password})){
          this.$router.push({ name: 'home' })
        }
      },
    },
  }
</script>
